// DELAY SERVICE PARALLAX (IE PERFORMANCE ISSUE)
function delayServiceParallax(el) {
  el
    .classList.add('parallax');
}


// PARALLAX LAUNCHER (adds parallax with parallax effects in css)
function startParallax() {
  // SOME OF PARALLAX FUNCTIONALITY ALSO IMPLEMENTED IN navbarSwitch FUNCTION !!!
  var parallaxEl = document.getElementsByClassName('js-parallax');
      halfWin = win.innerHeight / 1.5;
      for (var i = 0; i < parallaxEl.length; i++) {
        var pxEl = parallaxEl[i];
        var parallaxPos = pxEl.getBoundingClientRect().top;
        if ((parallaxPos > -(halfWin) && parallaxPos < halfWin)) {
          // init parallax
          if ((win.innerWidth > 750) && (pxEl.id != 'js-services')) {
            pxEl
              .classList.add('parallax');
          }
          // delay parallax effect on services (performance issues)
          else if ((win.innerWidth > 750) && (pxEl.id == 'js-services')) {
            var service = pxEl;
            setTimeout(function() {
              delayServiceParallax(service);
            }, 300);
          }
          // destroy
          else {
            parallaxEl[i]
              .classList.remove('parallax');
          }
        }
      }
}

// PARALLAX ROTATOR
function parallaxMoveRotator(el, speed, constant) {
  var amount = speed / constant;
  return amount;
}

// PARALLAX LAYERS MOVE EFFECT
function parallaxLayersMove(wind, elementsClass, speedAttr, wrapper, rotate) {
  var top =           wind.pageYOffset,
      layers =        document.getElementsByClassName(elementsClass),
      wrapperEl =     document.querySelector(wrapper) || '';
      wrapperOffset = wrapperEl.offsetTop,
      offset =        top - wrapperOffset,
      getSpeed =      (speedAttr == undefined || null) ? 'data-speed' : speedAttr,
      rotateMove =    rotate ? rotate : false;
  // predefined vars
  var layer, speed, yPos, saveStyles;
  for (var i = 0; i < layers.length; i++) {
    layer = layers[i];
    speed = layer.getAttribute(getSpeed);
    var yPos = -(offset * speed / 100);
    // with rotating effect
    if (rotate && win.innerWidth > 960) {
      var rotDeg = parallaxMoveRotator(layer, yPos, 3);
      // stop transforming above parent offset
      if (wrapperOffset > top) {
        layer
          .setAttribute('style', ' transform: translate3d(0px, 0px, 0px) rotate(0);');
      }
      else {
        layer
          .setAttribute('style', ' transform: translate3d(0px, ' + yPos + 'px, 0px) rotate(' + rotDeg + 'deg);');
      }
    }
    // no rotate
    else {
      // stop parallax transform above parent offset
      if (wrapperOffset > top) {
        layer
          .setAttribute('style', ' transform: translate3d(0px, 0px, 0px);');
      }
      else {
        layer
          .setAttribute('style', ' transform: translate3d(0px, ' + yPos + 'px, 0px);');
      }
    }
  }
}
