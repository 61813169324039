//- GLOBAL VARIABLES *****/
var win =           window,
    html =          document.querySelector('html'),
    homepage =      document.querySelector('.homepage'),
    //- header
    headerBg =      document.querySelector('.header__parallaxBg'),
    //- integros
    integromat =    document.querySelector('.integromat'),
    //- job
    job =           document.querySelector('.job'),
    jobContent =    document.querySelector('.job__content'),
    jobAnimation =  document.querySelector('.job__anim'),
    jobArticles =   document.querySelector('.job__articles'),
    jobArticle =    document.getElementsByClassName('job__article'),
    jobPerson =     document.getElementsByClassName('job__person'),
    pagItem = document.getElementsByClassName('job__pagItem');

// emptys
var twoStagedEl;
