//- SCROLL
function scroll(toElement, speed) {
  var windowObject =  window,
      windowPos =     windowObject.pageYOffset,
      pointer =       toElement.getAttribute('data-pointer') || toElement.getAttribute('href').slice(3),
      elem =          document.getElementById(pointer),
      elemOffset =    elem.offsetTop;
      var counter = setInterval(function() {
        windowPos;
        if (windowPos > elemOffset) { // bottom to top
          windowObject
            .scrollTo(0, windowPos);
          windowPos-=speed;
          if (windowPos <= elemOffset) { // cancel scrolling
            clearInterval(counter);
            windowObject
              .scrollTo(0, elemOffset+1);
          }
        } else { // top to bottom
          windowObject
            .scrollTo(0, windowPos);
          windowPos+=speed;
          if (windowPos >= elemOffset) { // cancel scrolling
            clearInterval(counter);
            windowObject
              .scrollTo(0, elemOffset+2);
          }
        }
      }, 15);
}
