// return all siblings
function siblings(el) {
  var result = [],
    node = el.parentNode.firstChild;

    for ( ; node; node = node.nextSibling )
       if ( node.nodeType == 1 && node != el)
          result
            .push(node);
    return result;
}

// DEBOUNCER (call something with interval)
function debouncer( func , timeout ) {
   var timeoutID, timeout = timeout || 200;
   return function () {
      var scope = this,
          args =  arguments;
      clearTimeout( timeoutID );
      timeoutID = setTimeout( function () {
          func
            .apply( scope , Array.prototype.slice.call( args ) );
      }, timeout );
   };
}

function substringTxt(ths, len) {
  var string = ths.innerText,
      substr = string.substring(0, len);
  if (string.length > len) {
    ths
      .innerText = substr + '... ';
  }
}

//- SET HEIGHT FROM HIGHEST ELEMENT
function calcHeight(elements, parent) {
  if (parent === null) {
    return false
  }
  var heights = [];
  for (var i = 0; i < elements.length; i++) {
    var elHeight = elements[i].offsetHeight;
    heights
      .push(elHeight);
  }

  //- return highest from array
  Array.prototype.max = function() {
    return Math.max.apply(null, this);
  };
  var max = Math.max.apply(null, heights);
  parent
    .style.height = max + 'px';
}
