// SET JOB HEIGHT
function jobHeight() {
  var jobSlides =   document.getElementsByClassName('job__slide'),
      jobWrapper =  document.querySelector('.job__contentWrapper');
  calcHeight(jobSlides, jobWrapper);
}

// JOB INTERACTION
var pointer, pointedPerson;

for (var i = 0; i < pagItem.length; i++) {
  var item = pagItem[i];
  //- HOVER
  item
    .addEventListener('mouseover', function() {
      pointer = this.getAttribute('data-jobid');
      pointedPerson = document.getElementsByClassName(pointer)[0];
      if (win.innerWidth > 960) {
        for (var i = 0; i < jobPerson.length; i++) {
          jobPerson[i]
            .classList.add('inactive');
          jobPerson[i]
            .classList.remove('active');
        }
        pointedPerson
          .classList.remove('inactive');
        pointedPerson
          .classList.add('active');
      }
    });
  item
    .addEventListener('mouseout', function() {
      for (var i = 0; i < jobPerson.length; i++) {
        // prevent continuous transition efect after parallax
        jobPerson[i]
          .classList.add('postParallax');
        // reset
        jobPerson[i]
          .classList.remove('inactive');
        jobPerson[i]
          .classList.remove('active');
      }
    });
  //- CLICK
  item
    .addEventListener('click', function() {
      var pointedArticle = document.getElementById(pointer);
      for (var i = 0; i < jobArticle.length; i++) {
        // reset focused / active persons and pagination items
        jobArticle[i]
          .classList.remove('focus');
        jobPerson[i]
          .classList.remove('focus');
        pagItem[i]
          .classList.remove('active');
          // hide persons
        jobPerson[i]
          .classList.add('hidden');
      }
      // show article
      pointedArticle
        .classList.add('focus');
      // show person
      pointedPerson
        .classList.remove('hidden');
      pointedPerson
        .classList.add('focus');
      // keep selected pagination item active
      this
        .classList.add('active');
      // set job to activated (different person show effect) after first animation
      setTimeout(function() {
        job
          .classList.add('activated');
      }, 200);
    });
}
