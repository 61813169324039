//- ### ### ### ### ### ### ### ###
//- EVENTS
//- ### ### ### ### ### ### ###

//- ### ### ### SCROLLER CONTROLS
var scroller =  document.getElementsByClassName('scroller__btn');

for (var i = 0; i < scroller.length; i++) {
  scroller[i]
    .addEventListener('click', function(e) {
      e.preventDefault();
      scroll(this, 15);
    }, false);
}

//- ### ### ### NAVIGATION CONTROLS
var navList =         document.getElementById('js-navbarList'),
    navItem =         document.getElementsByClassName('navigation__item'),
    navLink =         document.getElementsByClassName('navigation__link'),
    scrollSections =  document.getElementsByClassName('js-scroll'),
    menu =            document.getElementById('js-menu');

for (var i = 0; i < navLink.length; i++) {
  navLink[i]
    .addEventListener('click', function(e) {
      if (homepage) {
        e.preventDefault();
      }
      scroll(this, 40);
      for (var i = 0; i < navLink.length; i++) {
        navLink[i]
          .classList.remove('active');
      }
      this
        .classList.add('active');
      // close menu on tap
      if (win.innerWidth < 961) {
        menu
          .classList.remove('active');
        navList
          .classList.remove('active');
      }
      // reset job section to default
      if (this.classList.contains('js-job')) {
        job
          .classList.remove('activated');
        for (var i = 0; i < pagItem.length; i++) {
          pagItem[i]
            .classList.remove('active');
          jobArticle[i]
            .classList.remove('focus');
          jobPerson[i]
            .classList.remove('focus');
          jobPerson[i]
            .classList.remove('inactive');
          jobPerson[i]
            .classList.remove('active');
          jobPerson[i]
            .classList.remove('hidden');
        }
      }
    });
}

// mobile version
menu
  .addEventListener('click', function() {
    this
      .classList.toggle('active');
    this
      .nextElementSibling.classList.toggle('active');
  }, false);

// change active nav link on window scroll
var documentHeight;
var offsets =             [],
    pageSections =        document.getElementsByClassName('js-scroll'),
    pageSectionsLength =  pageSections.length,
    navItems =            document.getElementsByClassName('navigation__link'),
    navItemsLength =      navItems.length;

//- ### ### ### WINDOW ON LOAD EVENT FUNCTIONALITY *****/
win
  .addEventListener('load', function() {
    startParallax();
    parallaxLayersMove(this, 'js-headMove', 'data-speed','header');
    parallaxLayersMove(this, 'wheel', 'data-speed','.integromat', true);
    // calc height for job content
    calcHeight(jobArticle, jobContent);
    // calc height for job articles wrapper
    calcHeight(jobArticle, jobArticles);
    //- ### STORE SECTIONS HEIGHTS !!!! PLACE ALWAYS AT BOTTOM !!!!
    // store full page height
    documentHeight = html.scrollHeight;
    // store vertical position of page sections
    for (i = 0; i < pageSectionsLength; i++) {
      var  offset = pageSections[i].offsetTop;
      offsets.push(offset);
    }
    //navbar switcher
    navbarSwitch(offsets, documentHeight);
  });

//- ### ### ### WINDOW SCROLL EVENT FUNCTIONALITY *****/
  win
    .addEventListener('scroll', function(e) {
      //navbar switcher
      navbarSwitch(offsets, documentHeight);
      //parallax
      startParallax();
      // fix for headerbg transition delay
      headerBg && headerBg
        .classList.add('fix');
      // on scroll positions
      parallaxLayersMove(this, 'js-headMove', 'data-speed','header');
      parallaxLayersMove(this, 'wheel', 'data-speed','.integromat', true);
    });

//- ### ### ### WINDOW RESIZE EVENT FUNCTIONALITY *****/
  win
    .addEventListener('resize', debouncer(
      function(e) {
        // calc height for job content
        calcHeight(jobArticle, jobContent);
        // calc height for job articles wrapper
        calcHeight(jobArticle, jobArticles);
        //- ### REDO OFFSETS FOR SECTIONS (for navbar switch) !!!! PLACE ALWAYS AT BOTTOM !!!!
        offsets = [];
        // store full page height
        documentHeight = html.scrollHeight;
        // store vertical position of page sections
        for (i = 0; i < pageSectionsLength; i++) {
          var  offset = pageSections[i].offsetTop;
          offsets.push(offset);
        }
        // detect navbar pos on resize
        navbarSwitch(offsets, documentHeight);
      }, 100
    ));
