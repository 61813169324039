//- NAVBAR SWITCHER
function navbarSwitch(offsets, documentHeight) {
  var imgSource,
      windowOffset = this.pageYOffset,
      lastSection = pageSections[pageSectionsLength-1].offsetTop < windowOffset,
      bottomSection =   window.innerHeight + windowOffset;
  // change active navbar item according to current window position
  for (i = 0; i < pageSectionsLength; i++) {
    var section =       pageSections[i].id,
        link =          document.getElementsByClassName(section)[0],
        startSection =  pageSections[i].offsetTop <= windowOffset,
        endSection =    windowOffset < (offsets[i+1] || documentHeight),
        activeParallax = pageSections[i].querySelector('.js-parallax') || pageSections[i];
    // ABOVE SECTION
    if (startSection && endSection) {
      // prevent adding class for better performance
      if (!(link.classList.contains('active'))) {
        link
          .classList.add('active');
        // launch parallax for top/bottom sections
        if (win.innerWidth > 750 && activeParallax !== null) {
          activeParallax
            .classList.add('parallax');
        }
        else {
          activeParallax
            .classList.remove('parallax');
        }
      }
      // fix for bottom section
      } else if (documentHeight === bottomSection || lastSection) {
          navItems[navItemsLength-2]
            .classList.remove('active');
          navItems[navItemsLength-1]
            .classList.add('active');
      // OUT OF SECTION
      } else {
        link
          .classList.remove('active');
      }
  }
}
