//- CREATE PSEUDO SELECT PAGINATION
function selectPagination(pagWrapper) {
  if (win.innerWidth <= 960 && pagWrapper.classList.contains('active')) {
    var bulletDesc =  pagWrapper.querySelector('.swiper-pagination-bullet-active .pagination__desc'),
        descText =    bulletDesc.innerHTML,
        select =      document.createElement('div'),
        pagination =  pagWrapper.firstElementChild;
    select
      .innerHTML = descText;
    select
      .classList.add('pagination__select');
    // substring large text
    substringTxt(select, 30);
    if (pagWrapper.previousElementSibling) {
      select
        .classList.add('active');
    }
    var resText = pagWrapper.previousElementSibling;
    pagWrapper
      .parentNode.removeChild(resText);

    pagWrapper
      .parentNode.insertBefore(select, pagWrapper);
    // find position of select element and align item list with it
    var selectTop =     select.offsetTop,
        selectLeft =    select.offsetLeft,
        selectHeight =  select.offsetHeight,
        topPosition =   selectTop + selectHeight;
    // set list position only if select is visible
    if (select.offsetWidth > 0 && select.offsetHeight > 0) {
      pagWrapper
        .style.top = topPosition + 'px';
      pagWrapper
        .style.left = selectLeft + 'px';
    }
    else {
      pagWrapper
        .removeAttribute('style');
    }
  }
  else {
    pagWrapper
      .removeAttribute('style');
  }
}
